@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

/* font-family: "Raleway", sans-serif; 
  font-family: "Noto Sans", sans-serif;
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}

header {
  font-family: "Noto Sans", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
}
.headDetail {
  text-align: center;
}
.headDetail h1 {
  font-size: 50px;
  color: #1b6392;
}
.headDetail p {
  font-size: 12px;
  margin-top: -13px;
}
.headContact {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.headContact p {
  margin-left: 10px;
  font-size: 20px;
  color: black;
  font-weight: 500;
}

/* fa8232 */

/* nav bar */

nav {
  display: flex;
  background-color: #1b6392;
  padding-left: 40px;
}

.links {
  cursor: pointer;
  margin: 20px 30px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  position: relative;
}

.links::after,
.links::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  background-color: #fa8232;
  transition: width 0.3s ease;
}

.links::after {
  top: -8px;
  left: 0;
}

.links::before {
  bottom: -8px;
  right: 0;
}

.links:hover::after,
.links:hover::before {
  width: 100%;
}

/* home */

.home {
  display: flex;
}
.homeDetail {
  width: 50%;
  padding: 4% 5%;
}
.homeDetail h1 {
  font-size: 45px;
}
.homeDetail h1 > span {
  color: #1b6392;
}
.homeDetail p {
  font-size: 30px;
  margin: 5px 0;
}
.homeDetail .second {
  font-size: 15px;
  color: gray;
  line-height: 20px;
}
.homeImg {
  width: 50%;
  display: flex;
  
}
.homeImg img {
  margin-top: 16%;
  width: 80%;
  height: 300px;
   box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  border-radius: 5px 5px 5px 60px;
}
.homebtn {
  margin-top: 30px;
}
button {
  border: none;
  outline: none;
  background-color: #1b6392;
  color: white;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 3px 3px 25px 3px;
}
button:hover {
  background-color: #fa8232;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.secondbtn {
  margin-left: 10px;
}
.firstbtn:hover,
.secondbtn:hover {
  background-color: #fa8232;
  transition: 0.5s;
}

/* .quotes */
.quoteCon {
  display: flex;
  justify-content: center;
  margin: 5% 0;
}
.quotes {
  width: 65%;
  border: 3px solid #1b6392;
  border-radius: 10px;
  padding: 1.5% 2%;
}
.quotes > h1 {
  color: #1b6392;
  font-size: 37px;
  text-align: center;
}
.quotes img {
  width: 100%;
  height: 100px;
}
.serviceHeading {
  text-align: center;
  font-size: 45px;
  color: #1b6392;
  background-color: #f6f5fc;
  padding: 20px 0;
   overflow: hidden;
}
.serviceCon {
   overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Add spacing between the service items */
  justify-content: center; /* Center the items in the container */
  padding: 10px; /* Add padding around the container */
}

.serviceCon {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  background-color: #f6f5fc;
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  overflow: hidden;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 5px 5px 60px 5px;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px 5px 60px 5px;
  -moz-border-radius: 5px 5px 60px 5px;
  -ms-border-radius: 5px 5px 60px 5px;
  -o-border-radius: 5px 5px 60px 5px;
}

.service img {
  width: 100%;
  height: 150px;
  border-bottom: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
}

.service h3 {
  margin: 10px 0;
  font-size: 15px;
  color: #333;
}

.service p {
  font-size: 12px;
  color: #666;
}

.serBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.service button {
  font-size: 14px;
  padding: 5px 20px;
}

/* .message */

.messege {
  display: flex;
  overflow: hidden;
}
.messegeImg {
  width: 50%;
  padding: 5%;
}
.messegeImg img {
  width: 90%;
  border-radius: 5px 5px 60px 5px;
  margin-top: 15%;
}
.messegeDetail {
  width: 50%;
}
.messegeDetail h1 {
  color: #1b6392;
  font-size: 60px;
  line-height: 60px;
  margin-top: 13%;
}
.messegeDetail p {
  color: gray;
  font-size: 18px;
  padding-right: 30%;
  margin-top: 20px;
  line-height: 25px;
}

.think {
  padding: 70px 90px;
  margin: 90px 0;
  background-color: #f6f5fc;
  position: relative;
}

.think h6 {
  color: #1b6392;
  font-size: 170px;
  position: absolute;
  margin-top: 60px;
  left: 36%;
  opacity: 0.4;
}

.think h1 {
  text-align: center;
  font-size: 50px;
  color: #1b6392;
}
.think p {
  font-size: 26px;
  padding: 10px 20px;
  text-align: center;
  color: #666;
}
.sliders {
  padding: 50px 40px;
  position: relative;
}

.talk {
  width: 100%;
  position: relative;
  margin: 0px 0;
}
.talk img {
  width: 100%;
  height: 250px;
}
.talk {
  z-index: 0;
  display: flex;
  justify-content: center;
}
.talkbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 40px;
}
.innerbtn {
  display: flex;
}
.talkbtn h1 {
  color: white;
  font-size: 45px;
  margin-bottom: 15px;
}
.talkbtn button {
  background-color: #f9f9f9;
  color: #1b6392;
  padding: 12px 30px;
  font-weight: 530;
  font-size: 23px;
}
#talkbtn {
  margin-left: 12px;
}
.talkbtn button:hover {
  background-color: #fa8232;
  color: #f9f9f9;
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 3% 8% 10%;
}
.detail h2 {
  margin-bottom: 20px;
  color: #1b6392;
}
.detail p {
  font-size: 20px;
  margin-bottom: 10px;
}
#detailIcon {
  cursor: pointer;
}
#detailIcon:hover {
  color: #fa8232;
}
.other h2 {
  color: #1b6392;
  margin-bottom: 20px;
}
ul {
  list-style: none;
  cursor: pointer;
}
ul li {
  font-size: 20px;
  margin-bottom: 10px;
}
.conInfo h2 {
  color: #1b6392;
  margin-bottom: 20px;
}
.footerIcon {
  display: flex;
  align-items: center;
  line-height: 40px;
}
.footerIcon a {
  margin-left: 10px;
  color: black;
  text-decoration: none;
}
.Loc {
  width: 300px;
  height: 200px;
}
.map {
  height: 1px;
  border-radius: 10px;
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

/* about */

.about {
  display: flex;
  padding: 50px;
}
.aboutDetail {
  width: 50%;
}
.aboutDetail h1 {
  font-size: 60px;
  line-height: 55px;
  margin-bottom: 20px;
}
.aboutDetail span {
  color: #1b6392;
}
.aboutDetail h4 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}
.aboutDetail p {
  color: #666;
  font-size: 20px;
  line-height: 30px;
}

.aboutImg {
  width: 50%;
}
.aboutImg img {
  width: 70%;
  height: 280px;
  border-radius: 5px 5px 5px 60px;
  margin: 30px 0 0 20%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
}

.secondabout {
  display: flex;
  padding: 80px 50px;
  background-color: #f6f5fc;
}
.secondDetail {
  width: 50%;
  padding-left: 80px;
}
.secondDetail h1 {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 5px;
  color: #1b6392;
}

.secondDetail p {
  color: #666;
  font-size: 20px;
  line-height: 30px;
}

.secondImg {
  width: 50%;
  
}
.secondImg img {
  width: 80%;
  height: 280px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  border-radius: 5px 5px 60px 5px;
  margin: 30px 0 0 0%;
  -webkit-border-radius: 5px 5px 60px 5px;
  -moz-border-radius: 5px 5px 60px 5px;
  -ms-border-radius: 5px 5px 60px 5px;
  -o-border-radius: 5px 5px 60px 5px;
}

/* contact */

.contact {
  display: flex;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
}
.contactimg {
  width: 50%;
}
.contactImg img {
  width: 90%;
  height: 400px;
  margin-top: 60px;
  border-radius: 5px 5px 60px 5px;
  -webkit-border-radius: 5px 5px 60px 5px;
  -moz-border-radius: 5px 5px 60px 5px;
  -ms-border-radius: 5px 5px 60px 5px;
  -o-border-radius: 5px 5px 60px 5px;
}
.contactDetail {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contactDetail h1 {
  color: #1b6392;
  font-size: 40px;
}
.contactDetail p {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 15px 30px;
}

.contactForm {
  width: 100%;
  margin-left: 33%;
}
.contactForm label {
  color: #1b6392;
  font-size: 20px;
}
.contactForm input,
textarea {
  padding: 8px;
  width: 65%;
  border-radius: 10px;
  margin: 7px 0;
  border: none;
  outline: none;
  border: 1px solid #1b6392;
}

.contactForm button {
  width: 65%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(
    51,
    51,
    51,
    0.7
  ); /* Use rgba for semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  padding: 0 20%;
}

.popup-content {
  background-color: white;
  border-radius: 10px;
  padding: 30px 0 50px 0;
  position: relative;
  z-index: 9999;
  opacity: 1;
}

.popup h1 {
  text-align: center;
  color: #1b6392;
  margin-top: 40px;
  padding: 0 10px;
}

.popup-content button {
  border-radius: 10px;

  padding: 5px 15px;
  font-size: 30px;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 34px;
  cursor: pointer;
  color: #1b6392;
}

.S1,
.S2 {
  display: flex;
  padding: 10%;
  width: 100%;
}
.S1 h1 {
  color: #1b6392;
  font-size: 40px;
  margin: 30px 25% 10px 0%;
}
.S1 p {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  margin: 0px 25% 10px 0%;
}

.S1 {
  background-color: #f6f5fc;
}

.S1 img {
  width: 400px;
  height: 280px;
  border-radius: 5px 5px 5px 60px;
  -webkit-border-radius: 5px 5px 5px 60px;
  -moz-border-radius: 5px 5px 5px 60px;
  -ms-border-radius: 5px 5px 5px 60px;
  -o-border-radius: 5px 5px 5px 60px;
}
.S2 h1 {
  color: #1b6392;
  font-size: 40px;
  margin: 30px 0 10px 25%;
}
.S2 p {
  color: #666;
  font-size: 18px;
  line-height: 25px;
  margin: 0px 0 10px 25%;
}
.S2 img {
  border-radius: 5px 5px 60px 5px;
  width: 400px;
  height: 280px;
  -webkit-border-radius: 5px 5px 60px 5px;
  -moz-border-radius: 5px 5px 60px 5px;
  -ms-border-radius: 5px 5px 60px 5px;
  -o-border-radius: 5px 5px 60px 5px;
}

.apply {
  display: flex;

  justify-content: center;
  flex-direction: column;
}

.apply h1 {
  color: #1b6392;
  font-size: 50px;
  margin: 30px 0 20px 0;
  text-align: center;
}
.applyform {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px ;
}
.applyForm label{
 color: #1b6392;
 font-size: 18px;

}
.applyForm input{
  margin: 5px 0;
  width: 350px;
  padding: 7px ;
  border-radius: 10px;
  border: 1px solid #1b6392;
 
  outline: none;
}

.applyForm button{
  border-radius: 10px;

  width: 350px;
  margin-top: 10px;
}
@media screen and (max-width: 420px) {
  header {
    padding: 10px 5%;
  }
  .headDetail h1 {
    font-size: 40px;
    margin-bottom: 5px;
  }
  .headDetail p {
    font-size: 8px;
  }
  .headContact p {
    margin-left: 5px;
    font-size: 16px;
  }

  nav {
    padding-left: 10px;
  }
  .links {
    margin: 20px 12px;
    font-size: 14px;
  }

  .home {
    flex-direction: column;
  }
  .homeDetail {
    width: 100%;
  }
  .homeDetail h1 {
    text-align: center;
  }
  .homeDetail p {
    font-size: 25px;
    text-align: center;
  }
  .homebtn {
    display: flex;
    justify-content: center;
  }
  .homeImg {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .homeImg img {
    height: 250px;
  }

  .quoteCon {
    margin: 30% 0;
  }
  .quotes {
    width: 95%;
  }
  .quotes > h1 {
    font-size: 33px;
  }

  .serviceHeading {
    font-size: 35px;
    padding: 20px 4px;
  }
  .service {
    width: 250px;
  }
footer
  .messege {
    flex-direction: column;
  }
  .messegeDetail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .messegeDetail h1 {
    font-size: 40px;
    padding: 0 15px;
    line-height: 40px;
  }

  .messegeDetail p {
    padding: 0 14px;
  }

  .messegeImg {
    width: 100%;
    order: 1;
  }
  .messegeImg img {
    width: 100%;
    height: 250px;
  }
  .think {
    padding: 40px;
    margin: 40px 0;
  }
  .think h6 {
    font-size: 100px;
    margin-top: 100px;
    left: 25%;
  }
  .think h1 {
    font-size: 35px;
    line-height: 40px;
  }
  .think p {
    font-size: 16px;
    padding: 10px;
  }
  .sliders {
    padding: 20px;
  }
  .sliders p {
    word-break: break-all;
  }

  .talkbtn h1 {
    font-size: 43px;
    padding: 0 35px;
    line-height: 38px;
  }
  footer {
    flex-direction: column;
  }
  .detail,
  .other.conInfo.Loc {
    margin: 50px 0;
  }
  .Loc {
    width: 320px;
    height: 200px;
    margin-bottom: 50px;
  }

  .about {
    flex-direction: column;
    padding: 50px 20px;
  }
  .aboutDetail {
    width: 100%;
  }
  .aboutDetail h1 {
    text-align: center;
    font-size: 50px;
  }
  .aboutDetail h4 {
    text-align: center;
  }

  .aboutImg {
    width: 100%;
  }
  .aboutImg img {
    margin: 20px 0px;
    width: 100%;
    height: 250px;
  }

  .secondabout {
    flex-direction: column;
    padding: 50px 20px;
  }
  .secondDetail {
    width: 100%;
    padding-left: 0;
    order: 1;
  }
  .secondDetail h1 {
    text-align: center;
    font-size: 50px;
  }

  .secondImg {
    width: 100%;
    order: 2;
  }
  .secondImg img {
    margin: 20px 0px;
    width: 100%;
    height: 250px;
  }

  .contact {
    flex-direction: column;
    padding: 40px 10px;
  }
  .contactImg {
    display: none;
  }
  .contactDetail {
    width: 100%;
  }
  .contactDetail h1 {
    text-align: center;
    font-size: 35px;
  }
  .contactDetail p {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    padding: 10px 30px;
  }
  .contactForm {
    margin-left: 0;
    padding: 20px 10%;
  }
  .contactForm input,
  textarea {
    width: 100%;
  }
  .contactForm button {
    width: 100%;
  }

  .popup {
    padding: 8%;
  }
  .popup h1 {
    font-size: 25px;
    padding: 0 10px;
  }
  .S1,
  .S2 {
    flex-direction: column;
    padding: 10%;
  }
  .S1 img {
    width: 300px;
    height: 250px;
  }
  .S2 img {
    width: 300px;
    height: 250px;
    order: 1;
  }
  .S1 h1 {
    font-size: 30px;
    margin: 30px 0% 10px 0%;
  }
  .S1 p {
    font-size: 18px;
    line-height: 25px;
    margin: 0px 0% 20px 0%;
  }
  .S2 h1 {
    font-size: 30px;
    margin: 30px 0 10px 0%;
  }
  .S2 p {
    font-size: 18px;
    line-height: 25px;
    margin: 0px 0 10px 0%;
  }
  .apply h1{
font-size: 30px;
  }
}
